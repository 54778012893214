@import "../../variables";

.layout-left-settings-sidebar {
    .ap-panel {
        width: 170px;
        .ap-panel-body {
            padding: 12px;
            h4 {
                color: $primary-blue;
                padding-bottom: 10px;
            }
        }
        .leftbar-item {
            cursor: pointer;
            margin: 3px 0;
            padding: 4px 0;
            line-height: 1.1rem;
            .leftbar-text {
                padding-left: 0px;
                font-size: 0.9rem;
                color: $neutral-gray;
            }
            .leftbar-icon {
                color: $neutral-extremely-light-gray;
                &.leftbar-is-complete {
                    color: $primary-blue;
                }
            }
            &.leftbar-is-active {
                .leftbar-text {
                    font-weight: 500;
                    color: $primary-blue;
                }
                .leftbar-icon {
                    color: $neutral-extra-light-gray;
                    &.leftbar-is-complete {
                        color: $primary-blue;
                    }
                }
            }
        }
    }
}
