.adjustment-entries-page {
    .table-adustment-entries {
        td {
            vertical-align: middle;
        }
    }

    .adjustment-entry-form-modal {
        .ap-modal-footer-customize {
            width: 100%;
            .d-flex {
                width: 100%;
            }
        }
    }

    .reconciliation-table {
        th {
            vertical-align: bottom;
            text-align: center;
        }
        tr {
            border-radius: 0;
        }
        td {
            border-radius: 0 !important;
            width: 10%;
            &:nth-child(3n + 1) {
                border-right: 1px dotted gray;
            }
            &:nth-child(10) {
                border-right: 0;
            }
        }
    }

    .entries-dropdown-menu.ap-buttons-wrapper,
    .actions-dropdown-menu.ap-buttons-wrapper {
        div.ap-button-dropdown {
            right: auto;
        }
    }
}
