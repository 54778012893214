.center-container {
    width: 100%;
    height: 100%;
    .center-content {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    // z-index: 1;
    // position: absolute;
    margin: 0;
    // top: 50%;
    // left: 50%;
}
