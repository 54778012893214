@import "../../variables";

.calculation_create_panel {
    &.ap-panel {
        width: 100%;
        max-width: 780px;
        margin: auto;
        margin-top: 0px;
    }
    .ap-panel-content {
        h2 {
            color: $black;
        }
        span.entity-name {
            font-size: 22px;
        }
    }
}
