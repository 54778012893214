.ap-panel {
    //background: #ffffff;
    //box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.25);
}

.ap-header {
    backdrop-filter: none !important;
}

.ap-panel-body {
    padding: var(--spacing-6) var(--spacing-6) var(--spacing-6) var(--spacing-6);
}

.a-calendar,
.react-date-picker,
.react-daterange-picker,
.a-date-picker-header {
    width: 100%;
}
.a-calendar.error .ap-field-wrapper {
    background-color: rgba(var(--color-text-error-rgb), var(--opacity-3));
    box-shadow: inset 0 0 0 0.0625rem var(--color-text-error);
    .ap-field-input {
        color: var(--color-text-error);
    }
}

label.form-label[required]::after {
    content: "*";
}

.ap-notifications {
    z-index: 99999;
}

.ap-notification {
    &.fullheader {
        width: 98%;
        background-color: white;
        &.warning {
            background-color: $warning-yellow;
        }
    }
    .ap-notification-link {
        color: $primary-blue;
        &:hover {
            color: $secondary-blue;
        }
    }
}

.form-control-number input {
    text-align: right;
}

.ap-fileupload-footer-delete-attachment {
    display: none !important;
}

.ap-comment-header-action .more {
    display: none !important;
}
