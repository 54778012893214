@import "../../variables";

#layout-node {
    height: 100%;
    display: flex;
    flex-direction: column;
}

// 30px is from Figma, also it looks nice like that
%content-padding {
    padding: 30px calc(var(--spacing-6) * 2) var(--spacing-4) calc(var(--spacing-6) * 4);
    width: calc($margin-header-width - var(--spacing-4) * 2);
}

#content-body {
    flex: 1 0 auto;
}

.content-padding {
    @extend %content-padding;
}

.content-minimal-padding {
    padding: 20px;
}

#layout-footer-container {
    //flex-shrink: 0;
    //@extend %content-padding;
    padding: 20px;
}

.user-avatar-wrapper {
    #groupdropdown {
        left: auto;
    }
    .custom-node {
        &:focus {
            border-radius: 50% !important;
            .keyboard-focus {
                outline: auto 5px -webkit-focus-ring-color;
                outline-offset: var(--spacing-1);
            }
        }
        &:focus:not(.keyboard-focus) {
            outline: none;
            box-shadow: 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
        }
    }
}
