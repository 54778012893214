.custom-search {
    margin-top: -10px;
    max-width: 320px; // Make it look the same as in Figma
    border: 0;

    .ap-search-input {
        box-shadow: none !important;
        height: 40px;
        input {
            padding: 0 15px 0 35px;
        }
    }
}
