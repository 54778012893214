@import "../../variables";

.ap-header-options-icon-wrapper {
    display: none !important;
}

#header {
    background: #ffffff;
    min-width: 1070px;
    .ap-header.ap-header-responsive-default {
        padding: 0 15px;
    }
    .ap-header-options {
        padding-left: 30px !important;
    }
    .ap-header-options-item {
        margin-top: 0;
        padding: 0 5px;
        border-radius: 0px;
        vertical-align: middle;
        height: 60px;

        &.active-nav-item {
            border-bottom: 5px solid #ffc107;
            margin-bottom: -5px;
        }
    }
    .ap-header-options-label {
        font-size: 95%;
        line-height: 1.1rem;
        text-align: center;
    }
}
