.table-data-check {
    margin-bottom: 1em;
    overflow-x: auto;
    table {
        tbody {
            td {
                vertical-align: middle;
            }
        }
    }
}

.table-tb-sumarization {
    margin-bottom: 1em;
}
