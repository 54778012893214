@import "../../variables";

.icon-client-overview-arrow {
    margin-right: 30px;
    margin-top: -5px;
    transform: rotate(-90deg);
}

.upper-box-clients-container-container {
    padding-right: var(--spacing-6);
}

// .not-active {
//     overflow: hidden;
//     height: 0px;
//     // transition: height 0.3s ease;
// }

// .active {
//     overflow: visible;
//     height: 100%;
// }
.clientsNotFound {
    height: 400px;
    text-align: -webkit-center;
    display: inherit;
    h4 {
        text-decoration: underline 3px;
        text-underline-offset: 22px;
        max-width: 300px;
    }
    .Appkit4-icon {
        width: 40px !important;
    }
    .col {
        padding-top: 100px;
    }
    .Appkit4-icon {
        margin-bottom: 25px;
    }
    .ap-button {
        margin-top: 40px;
    }
}
.ap-panel.no-clients {
    max-height: 400px;
    height: 100%;
}
// .ap-panel-bordered {
//     height: 400px;
// }

.entity-item-wrap {
    padding-top: var(--spacing-2);
    @extend %bs-padding-row-overwrite;

    // &.active >
    .entity-item-bg {
        height: 54px;
        //box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.25);
        border-radius: var(--border-radius-3);
        background-color: #ffffff;
    }

    & & {
        padding-left: var(--spacing-6);
        // If you wish to apply padding to the right,
        // same as as the top level, remove the line bellow
        padding-right: 0 !important;
    }

    h5,
    h6 {
        margin-bottom: 0 !important;
    }

    .entity-item-items-wrapper {
        width: 100%;
    }

    .dropdown-clients-icon {
        padding-left: calc(var(--spacing-6) * 1.5) !important;
        padding-right: calc(var(--spacing-6) * 1.5) !important;
    }

    .icon-entity-item {
        margin-right: var(--spacing-6);
    }

    .clickable:hover {
        cursor: pointer;
    }

    svg.star-bi-filled path {
        fill: $primary-blue;
    }

    $activation-time: 0.25s;
    %transform-setting {
        transition: transform $activation-time;
    }
    .chevron-active {
        @extend %transform-setting;
        transform: rotate(-180deg);
    }
    .chevron-deactive {
        @extend %transform-setting;
        transform: rotate(-360deg);
    }
}
