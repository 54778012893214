@import "../../../variables";
.filter-form-component {
    display: flex;
    padding: 10px 5px;
    border-bottom: 2px solid $neutral-extremely-light-gray;
    &:last-child {
        border-bottom: 0;
    }
    .ap-dropdown {
        margin-right: 7px;
    }
    .ap-dropdown-list {
        display: block !important;
    }
    .value-wrapper {
        .ap-dropdown-select {
            width: 250px;
            margin-bottom: 10px;
        }
        .value-input-wrapper {
            margin-bottom: 10px;
            width: 160px;
        }
    }
    .ap-dropdown-select {
        width: 191px;
        .ap-field-wrapper {
            background-color: $secondary-extremely-light-blue !important;
        }
        display: inline-block;
    }
    .logic-operator-or {
        padding: 12px;
    }
    button.add-value-button {
        //border: 1px solid red;
        &.ap-button-icon {
            margin: 3px 0 0 0;
            padding: 8px 2px 2px 6px;
        }
    }
}

.temporary-filter {
    .ap-panel-body {
        padding-top: 0px !important;
    }
}

.page-panel-header {
    margin-bottom: 0px !important;
}
