@import "../../variables";

.calculation-form {
    .ap-panel-body {
        background-color: #eefedb;
    }
    .ap-field-wrapper {
        height: 2rem !important;
        .ap-field-input {
            //padding: 2px;
            margin: 8px !important;
        }
    }

    .ap-accordion-toggle {
        background-color: #c5e0b2;
        border: 1px solid gray;
        padding: 1px 4px !important;
    }
    .ap-accordion-title {
        font-size: 0.9rem !important;
    }
    .ap-accordion-content {
        padding-top: 10px !important;
        background-color: #eefedb;
        overflow: visible !important;
    }

    .react-date-picker {
        .ap-field-suffix-wrapper {
            height: 2rem;
            span.Appkit4-icon {
                width: 1.5rem !important;
                height: 1.5rem !important;
                line-height: 1.5rem !important;
            }
        }
    }
    textarea {
        height: 300px !important;
    }
}
