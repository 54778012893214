.tb-je-upload-container {
    .ap-fileupload-footer-delete-attachment {
        display: none !important;
    }
}
.ap-progress-stepper-container {
    padding-top: 0px;
}

.ap-progress-stepper-wrapper .ap-progress-stepper-bar-inner.bar-warning {
    background-color: var(--color-background-warning) !important;
}

.ap-progress-stepper-wrapper .ap-progress-stepper-btn .warning-status {
    color: black !important;
    position: relative !important;
    z-index: 9999 !important;
}

.ap-progress-stepper-wrapper .ap-progress-stepper-btn.warning-step-selected {
    background-color: #ffbf1f !important;
    z-index: unset !important;
    position: relative !important;
    opacity: 0.24;
    cursor: pointer;
}

.ap-progress-stepper-wrapper {
    padding-bottom: 20px !important;
    width: 100%;
}

#content-body {
    padding: var(--spacing-6) var(--spacing-6) var(--spacing-6) var(--spacing-6);
}
