.deferred-tax-table-wrapper {
    overflow: auto;
    max-height: calc(100vh - 160px);
    td {
        padding-top: 5px !important;
        padding-bottom: 1px !important;
        //margin: 0 !important;
        line-height: 1.15 !important;
        vertical-align: middle;
        .btn-small {
            height: 1.5rem !important;
            padding: 0px !important;
        }
    }
    thead {
        position: sticky;
        top: 0;
        background-color: white;
    }
    th {
        line-height: 1 !important;
        padding: 2px 8px 8px 8px !important;
        min-width: 90px;
        vertical-align: middle;
    }
}
