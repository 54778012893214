@import "../../variables";
.settings-entity-specific-panel {
    &.ap-panel {
        width: 100%;
        max-width: 960px;
        margin: auto;
        margin-top: 35px;
    }
    .ap-panel-content {
        h2 {
            color: $black;
        }
        h3 {
            margin: 20px 0 15px 0;
            color: $black;
            border-bottom: 3px solid $primary-blue;
            padding-right: 15px;
            display: inline-block;
        }
        span.entity-name {
            font-size: 22px;
        }
    }
    .ap-panel {
        box-shadow: 0px 1px 5px rgb(0 0 0 / 25%);
    }

    .entity-member-wrapper {
        &:not(:last-child) {
            border-bottom: 1px solid $neutral-extremely-light-gray;
        }
        margin: 5px 0 15px 0;
    }
}
