@import "../../../variables";

.uploadAttachment {
    transition: all 0.4s ease-in-out;
    border: 1px solid $neutral-extra-light-gray;
    height: 0;
    opacity: 0;
}

.active {
    height: 250px !important;
    opacity: 1;
}
.ap-fileupload-footer {
    display: none !important;
}
