// .trial-balance-search-panel {
//     border-bottom: 1px dotted #d1d1d1;
//     padding-bottom: 20px;
//     .mt-2{
//         margin-top: 0px !important;
//     }
//     .d-flex {
//         margin-top: 10px;
//     }
//     .labelBottom{
//         align-items: flex-end;
//         display: flex;
//     }
//     .ap-field-wrapper{
//         width: 200px;
//     }
//     .mx-1{
//         padding-right: 40px;
//     }
// }
.table-trial-balance {
    &.noshadow {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        padding: 0;
    }
    table {
        border: 1px solid #d1d1d1;
        padding: 8px;
        border-radius: 5px;
        tbody {
            td {
                vertical-align: top;
                .ap-field-wrapper {
                    height: 2rem !important;
                }
                input.ap-field-input {
                    margin: 0 10px !important;
                    min-width: 200px;
                }
                .ap-field .ap-field-wrapper {
                    margin-top: 4px;
                }
                .tagList {
                    padding: 0;
                    list-style-type: none;
                    text-align: left;
                    display: inline-block;
                    margin-bottom: 0;
                    .ap-tag.ap-tag-sm {
                        height: inherit !important;
                    }
                    li {
                        margin-right: 4px;
                        margin-bottom: 4px;
                        display: inline-block;
                        .select-tag-dropdown.ap-dropdown.ap-dropdown-single {
                            .Appkit4-icon.icon-down-chevron-outline {
                                margin-top: var(--spacing-3);
                            }
                            .ap-field-wrapper {
                                width: 120px;
                            }
                            /*.ap-dropdown-list {
                                width: 250px;
                            }*/
                            .ap-field-title-container {
                                top: var(--spacing-2);
                            }
                        }
                        .ap-dropdown-list {
                            right: 0 !important;
                            left: inherit !important;
                        }
                    }
                }
                &:last-child {
                    min-width: 150px;
                    max-width: 300px;
                }
            }
        }
    }
}
.col-char-width {
    width: 1ch !important;
    display: flex !important;
    justify-content: center !important;
    place-items: self-end !important;
}
