@import "/node_modules/sass-rem/_rem.scss";
@import "/node_modules/@appkit4/styles/scss/variables";
.ap-list-item-draggable {
    display: flex;
    width: 100%;
    border-radius: var(--border-radius-3);
    padding: var(--spacing-4);
    align-items: center;

    .Appkit4-icon {
        font-size: 1rem;
        color: var(--color-background-tertiary);
        margin-right: var(--spacing-3);
        width: 1rem;
        height: 1rem;
        line-height: 1rem;
    }

    .ap-list-item-draggable-box {
        display: flex;
        flex-direction: column;

        .ap-list-item-draggable-header {
            font-weight: var(--font-weight-2);
            font-size: 1.5rem;
            line-height: 2rem;
            letter-spacing: -0.025rem;
            color: var(--color-text-heading);
        }

        .ap-list-item-draggable-body {
            font-size: 1rem;
            font-weight: var(--font-weight-1);
            line-height: 1.5rem;
            letter-spacing: -0.025rem;
            text-align: left;
            color: var(--color-text-body);
        }
    }
}
