@import "../../variables";

.tabLabel {
    font-size: 0.9rem;
    &:hover {
        font-size: 0.95rem;
    }
}

.groupAccordionItem {
    background: transparent;
    border: 1px solid $secondary-blue;
    margin-bottom: 15px !important;
    &:hover {
        background-color: white;
    }
    .ap-accordion-title {
        color: yellow !important;
    }
}
