.comments {
    .comments-short-name {
        padding-top: 10px;
        padding-bottom: 0px;
    }
    .comments-detail {
        padding-bottom: 5px;
        .comments-likes {
            display: none !important;
        }
        .comments-images {
            display: none !important;
        }
    }
}

.ap-feeds-comments {
    .ap-feeds-comments-body {
        max-height: 80px;
        .ap-field textarea {
            max-height: 50px;
        }
    }
}
