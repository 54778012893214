$primary-blue: #415385;
$secondary-blue: #9aa4be;
$secondary-extremely-light-blue: #e6ecff;
$third-blue: #203570;
$primary-orange: #d04a02;
$secondary-orange: #fb734d;
$primary-teal: #26776d;
$secondary-teal: #50ad95;
$primary-red: #e0301e;
$secondary-red: #e96e61;
$primary-pink: #d93954;
$secondary-pink: #e27588;
$error-red: #c52a1a;
$warning-yellow: #ffbf1f;
$success-green: #22992e;
$neutral-extremely-light-gray: #ebebeb;
$neutral-extra-light-gray: #c3c3c3;
$neutral-light-gray: #757575;
$neutral-gray: #474747;
$neutral-dark-gray: #2d2d2d;
$text-header: #2d2d2d;
$text-description: #474747;
$text-timestamp: #696969;
$black: #000000;
$white: #ffffff;

$margin-header-one-side: 9.5px;
$margin-header-width: calc(100% - $margin-header-one-side * 2);

%bs-padding-row-overwrite {
    padding-left: var(--spacing-6) !important;
    padding-right: var(--spacing-6) !important;
}

// ------- Predefined by PwC START -------

// CSS Grid variables
#root,
::after,
::before {
    --appkit-rows: 1;
    --appkit-columns: 12;
    --appkit-gap: 1rem;

    --appkit-gutter-x: 1rem;
    --appkit-gutter-y: 0;
}

// Grid system variables
$grid-columns: 12;
$grid-gutter-width: 1rem;
$grid-row-columns: 6;

$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 1240px,
    lg: 1440px,
);

@mixin make-cssgrid($columns: $grid-columns, $breakpoints: $grid-breakpoints) {
    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        @include media-breakpoint-up($breakpoint, $breakpoints) {
            @if $columns>0 {
                @for $i from 1 through $columns {
                    .g-col#{$infix}-#{$i} {
                        grid-column: auto / span $i;
                    }
                }

                // Start with `1` because `0` is and invalid value.
                // Ends with `$columns - 1` because offsetting by the width of an entire row isn't possible.
                @for $i from 1 through ($columns - 1) {
                    .g-start#{$infix}-#{$i} {
                        grid-column-start: $i;
                    }
                }
            }
        }
    }
}

$container-max-widths: (
    sm: 600px,
    md: 1240px,
    lg: 1440px,
);

// ------- Predefined by PwC END -------
