/* Font is automatically set by react appkit */

@import "variables";

@import "./appkit-overwrites";
@import "../node_modules/@appkit4/styles/appkit.min.css";
@import "@appkit4/react-components/dist/styles/appkit4-react.min.css";

@import "./bootstrap-overwrites";
@import "~bootstrap/scss/bootstrap";

body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "PwC Helvetica Neue", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    background-color: $neutral-extremely-light-gray;
}

#root {
    height: 100%;
}

h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    /* identical to box height */

    /* Third - Blue */

    color: $third-blue;
}

h2,
h3,
h4,
h5,
h6 {
    &.as-link {
        color: $primary-blue;
        cursor: pointer;
    }
}

a {
    color: $primary-blue;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.fake-link {
    @extend a;
}

@each $name,
    $strength
        in (
            "0-5": 0.5px,
            "0-75": 0.75px,
            "1": 1px,
            "1-25": 1.25px,
            "1-5": 1.5px,
            "1-75": 1.75px,
            "2": 2px,
            "2-25": 2.25px,
            "2-5": 2.5px,
            "2-75": 2.75px,
            "3": 3px,
            "3-25": 3.25px,
            "3-5": 3.5px,
            "3-75": 3.75px,
            "4": 4px
        )
{
    svg.icon-strong-#{$name} {
        path {
            stroke: #000000;
            stroke-width: #{$strength};
        }
    }
    i.icon-strong-#{$name} {
        &::before {
            -webkit-text-stroke: #{$strength};
            // font-weight: $weight !important;
        }
    }
}
svg.icon-primary path {
    stroke: $primary-blue !important;
}
i.icon-primary {
    color: $primary-blue !important;
}

.input-type-number {
    .ap-field-title-container {
        display: none;
    }
    input {
        text-align: right;
        margin: 0 !important;
        padding: 4px 12px !important;
    }
}

.ap-panel.question-panel {
    opacity: 1;
    transition: all 0.2s ease-in-out;

    &.questionHidden {
        opacity: 0;
        height: 0;
    }
}
